/* 440px */
/* 512px */
/* 48em - 1px */
/* 64em - 1px */
/* 1280px */
/* 1600px */
/* 120em - 1px */
@font-face {
  font-family: 'icomoon';
  src: url('/fonts/icomoon.eot?-2jtdr3');
  src: url('/fonts/icomoon.eot?#iefix-2jtdr3') format('embedded-opentype'), url('/fonts/icomoon.woff?-2jtdr3') format('woff'), url('/fonts/icomoon.ttf?-2jtdr3') format('truetype'), url('/fonts/icomoon.svg?-2jtdr3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal; }

/**
 * @section CSS Reset
 * Meyer's CSS Reset, Normalized.css, and custom code.
 * @link http://meyerweb.com/eric/tools/css/reset/
 * @link http://necolas.github.io/normalize.css/
 */
/**
 * Mobile screen resizing
 * @link http://dev.w3.org/csswg/css-device-adapt/
 */
@-webkit-viewport {
  width: device-width;
  zoom: 1.0; }

@-moz-viewport {
  width: device-width;
  zoom: 1.0; }

@-ms-viewport {
  width: device-width;
  zoom: 1.0; }

@-o-viewport {
  width: device-width;
  zoom: 1.0; }

@viewport {
  width: device-width;
  zoom: 1.0; }

/**
 * Remove browser defaults
 */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, button, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/**
 * Add box sizing to everything
 * @link http://www.paulirish.com/2012/box-sizing-border-box-ftw/
 */
*, *:before, *:after {
  box-sizing: border-box; }

/**
 * Set display type for HTML5 semantic elements
 */
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

/**
 * Force scrollbar display to prevent jumping on pages.
 * Fix text resize bug on mobile devices.
 */
html {
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

/**
 * Display audio, canvas, and video elements as inline block elements.
 */
audio, canvas, video {
  display: inline-block; }

/**
 * Prevent modern browsers from displaying audio without controls.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Prevent img and video elements from spilling outside of the page on smaller screens.
 */
img, video {
  max-width: 100%;
  height: auto; }

/**
 * Prevent iframe, object, and embed elements from spilling outside of the page on smaller screens.
 * height: auto causes iframes to smush, so it's omitted here.
 */
iframe, object, embed {
  max-width: 100%; }

/**
 * Hide the template element in IE, Safari, and Firefox < 22.
 */
template {
  display: none;
  visibility: hidden; }

/**
 * Prevents IE from making scaled images look like crap
 */
img {
  -ms-interpolation-mode: bicubic; }

/**
 * Address outline inconsistency between Chrome and other browsers.
 */
a:focus, button:focus {
  outline: thin dotted;
  outline: 0.3125em auto -webkit-focus-ring-color;
  outline-offset: -0.15625em; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:hover, a:active {
  outline: 0; }

/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted;
  cursor: help; }

/**
 * @section Overrides
 * Nudge and tweak alignment, spacing, and visibility.
 */
/**
 * Text sizes
 */
.text-small {
  font-size: 0.9375em; }

.text-large {
  font-size: 1.1875em;
  line-height: 1.4; }
  @media (min-width: 1023px) {
    .text-large {
      font-size: 1.3125em; } }

/**
 * Text colors
 */
.text-muted {
  color: #808080; }

/**
 * Text alignment
 */
.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

/**
 * Floats
 */
.float-left {
  float: left; }

.float-center {
  float: none;
  margin-left: auto;
  margin-right: auto; }

.float-right {
  float: right; }

/**
 * Margins
 */
.no-margin {
  margin: 0; }

.no-margin-top {
  margin-top: 0; }

.no-margin-bottom {
  margin-bottom: 0; }

.margin-top {
  margin-top: 1.5625em; }

.margin-bottom {
  margin-bottom: 1.5625em; }

.margin-bottom-small {
  margin-bottom: 0.5em; }

.margin-bottom-large {
  margin-bottom: 2em; }

/**
 * Padding
 */
.no-padding {
  padding: 0; }

.no-padding-top {
  padding-top: 0; }

.no-padding-bottom {
  padding-bottom: 0; }

.padding-top {
  padding-top: 1.5625em; }

.padding-top-small {
  padding-top: 0.5em; }

.padding-top-large {
  padding-top: 2em; }

.padding-bottom {
  padding-bottom: 1.5625em; }

.padding-bottom-small {
  padding-bottom: 0.5em; }

.padding-bottom-large {
  padding-bottom: 2em; }

/**
 * Visibility
 */
/**
 * Visually hide an element, but leave it available for screen readers
 * @link https://github.com/h5bp/html5-boilerplate/blob/master/dist/css/main.css
 * @link http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.screen-reader {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

/**
 * Extends the .screen-reader class to allow the element to be focusable when navigated to via the keyboard
 * @link https://github.com/h5bp/html5-boilerplate/blob/master/dist/css/main.css
 * @link https://www.drupal.org/node/897638
 */
.screen-reader-focusable:active, .screen-reader-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

/**
 * @workaround
 * @affected IE 8/9/10
 * @link http://juicystudio.com/article/screen-readers-display-none.php
 */
[hidden] {
  display: none;
  visibility: hidden; }

/**
 * Contain floats
 * The space content is one way to avoid an Opera bug when the `contenteditable` attribute is included anywhere else in the document.
 * @link https://github.com/h5bp/html5-boilerplate/blob/master/dist/css/main.css
 */
.clearfix:before, .clearfix:after {
  display: table;
  content: " "; }

.clearfix:after {
  clear: both; }

/**
 * @section Print
 * Styling for printed content. Adapted from HTML5BP.
 * @link http://html5boilerplate.com
 */
@media print {
  /**
	 * Universal selector.
	 * Reset all content to transparent background, black color, and remove box and text shadows.
	 */
  * {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  /**
	 * Specifies page margin
	 */
  @page {
    margin: 0.5cm; }

  /**
	 * Underline all links
	 */
  a, a:visited {
    text-decoration: underline; }
  /**
	 * Show URL after links
	 */
  a[href]:after {
    content: " (" attr(href) ")"; }
  /**
	 * Don't show URL for internal links
	 */
  a[href^="#"]:after {
    content: ""; }
  /**
	 * Specifies the minimum number of lines to print at the top and bottom of a page.
	 */
  p, h1, h2, h3 {
    orphans: 3;
    widows: 3; }
  /**
	 * Avoid inserting a page break after headers
	 */
  h1, h2, h3 {
    page-break-after: avoid; }
  /**
	 * Change border color on blockquotes and preformatted text.
	 * Avoid page breaks inside the content
	 */
  pre, blockquote {
    border-color: #999;
    page-break-inside: avoid; }
  /**
	 * Displayed as a table header row group
	 */
  thead {
    display: table-header-group; }
  /**
	 * Avoid inserting a page break inside table rows and images
	 */
  tr, img {
    page-break-inside: avoid; } }

html, body {
  height: 100%;
  background-color: #e4e1de; }

/* ==========================================================================
	#FULL WIDTH / LAYOUT ELEMENTS
	========================================================================== */
.wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative; }

/* ==========================================================================
   #ICON FONTS
   ========================================================================== */
@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?-jcod1t');
  src: url('fonts/icomoon.eot?#iefix-jcod1t') format('embedded-opentype'), url('fonts/icomoon.woff?-jcod1t') format('woff'), url('fonts/icomoon.ttf?-jcod1t') format('truetype'), url('fonts/icomoon.svg?-jcod1t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-star:before {
  content: "\e601"; }

.icon-plane:before {
  content: "\e605"; }

.icon-house:before {
  content: "\e60e"; }

.icon-car:before {
  content: "\e60f"; }

.icon-graduation:before {
  content: "\e610"; }

.icon-piggy-bank:before {
  content: "\e611"; }

.icon-calendar:before {
  content: "\e612"; }

.icon-money:before {
  content: "\e613"; }

.icon-stamp:before {
  content: "\e614"; }

.icon-loan:before {
  content: "\e615"; }

.icon-calculator:before {
  content: "\e616"; }

.icon-dot:before {
  content: "\e619"; }

.icon-login:before {
  content: "\e618"; }

.icon-online-chat:before {
  content: "\e617"; }

.icon-phone:before {
  content: "\e607"; }

.icon-email:before {
  content: "\e603"; }

.icon-location:before {
  content: "\e606"; }

.icon-search:before {
  content: "\e608"; }

.icon-twitter:before {
  content: "\e609"; }

.icon-facebook:before {
  content: "\e604"; }

.icon-youtube:before {
  content: "\e60a"; }

.icon-arrow-left:before {
  content: "\e60b"; }

.icon-arrow-right:before {
  content: "\e602"; }

.icon-arrow-down:before {
  content: "\e60c"; }

.icon-arrow-up:before {
  content: "\e60d"; }

.icon-close:before {
  content: "\e600"; }

/* ==========================================================================
   #NTC
   ========================================================================== */
#ntc {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 2em;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Source Sans Pro', Arial; }

#ntc #colorname {
  font-size: 3em;
  margin-bottom: 0.5em;
  text-align: center;
  color: #231f20; }

#ntc #colorname sup {
  font-size: 0.6em;
  margin-left: 6px;
  color: #333; }

#ntc #colorname sup#solid {
  color: #231f20; }

#ntc #colorpick {
  display: none;
  font-size: 0.8em;
  color: #231f20; }

#ntc #colorpick select {
  width: 195px; }

#ntc #colorsolid {
  position: absolute;
  border: 2px solid #DDD;
  right: 2em;
  width: 32px;
  height: 32px; }

#ntc #colorpanel {
  position: relative; }

#ntc #colorinp {
  width: 100%;
  font-size: 2em;
  padding: 0.5em;
  border: 0;
  color: #231f20;
  background-color: rgba(255, 255, 255, 0.4);
  margin-bottom: 1em; }

#ntc #colorlegend {
  text-align: center;
  font-size: 0.8em;
  margin: 8px; }

