@import "config";
@import "mixins";
@import "components/reset";
// @import "components/grid";
// @import "components/typography";
// @import "components/code";
// @import "components/buttons";
// @import "components/forms";
// @import "components/svg";
// @import "components/meanmenu";
// @import "components/superfish";
// @import "components/slick";
// @import "components/slick-theme";
//@import "components/spinner";
// @import "components/iconfonts";
// Import add-ons here...
@import "components/overrides";
@import "components/print";


html, body {
	height: 100%;
	background-color: #e4e1de;
}

/* ==========================================================================
	#FULL WIDTH / LAYOUT ELEMENTS
	========================================================================== */

.wrapper {
	width: 100%;
	height: 100%;
	overflow:hidden;
	position:relative;
}

/* ==========================================================================
   #ICON FONTS
   ========================================================================== */


@font-face {
	font-family: 'icomoon';
	src:url('fonts/icomoon.eot?-jcod1t');
	src:url('fonts/icomoon.eot?#iefix-jcod1t') format('embedded-opentype'),
		url('fonts/icomoon.woff?-jcod1t') format('woff'),
		url('fonts/icomoon.ttf?-jcod1t') format('truetype'),
		url('fonts/icomoon.svg?-jcod1t#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-star:before {
	content: "\e601";
}

.icon-plane:before {
	content: "\e605";
}

.icon-house:before {
	content: "\e60e";
}

.icon-car:before {
	content: "\e60f";
}

.icon-graduation:before {
	content: "\e610";
}

.icon-piggy-bank:before {
	content: "\e611";
}

.icon-calendar:before {
	content: "\e612";
}

.icon-money:before {
	content: "\e613";
}

.icon-stamp:before {
	content: "\e614";
}

.icon-loan:before {
	content: "\e615";
}

.icon-calculator:before {
	content: "\e616";
}

.icon-dot:before {
	content: "\e619";
}

.icon-login:before {
	content: "\e618";
}

.icon-online-chat:before {
	content: "\e617";
}

.icon-phone:before {
	content: "\e607";
}

.icon-email:before {
	content: "\e603";
}

.icon-location:before {
	content: "\e606";
}

.icon-search:before {
	content: "\e608";
}

.icon-twitter:before {
	content: "\e609";
}

.icon-facebook:before {
	content: "\e604";
}

.icon-youtube:before {
	content: "\e60a";
}

.icon-arrow-left:before {
	content: "\e60b";
}

.icon-arrow-right:before {
	content: "\e602";
}

.icon-arrow-down:before {
	content: "\e60c";
}

.icon-arrow-up:before {
	content: "\e60d";
}

.icon-close:before {
	content: "\e600";
}


/* ==========================================================================
   #NTC
   ========================================================================== */

#ntc {
	position: absolute;
	background-color: rgba(255,255,255,0.2);
	padding: 2em;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-family: 'Source Sans Pro', Arial;
}

#ntc #colortag {

}

#ntc #colorname {
	font-size: 3em;
	margin-bottom: 0.5em;
	text-align: center;
	color: #231f20;
}

#ntc #colorname sup {
  font-size: 0.6em;
  margin-left: 6px;
  color: #333;
}

#ntc #colorname sup#solid {
  color: #231f20;
}

#ntc #colorpick {
	display: none;
  font-size: 0.8em;
  color: #231f20;
}

#ntc #colorpick select {
  width: 195px;
}

#ntc #colorbox {

}

#ntc #colorsolid {
  position: absolute;
  border: 2px solid #DDD;
  right: 2em;
  width: 32px;
  height: 32px;
}

#ntc #colorpanel {
  position: relative;
}

#ntc #colorhex {

}


#ntc #colorinp {
	width: 100%;
	font-size: 2em;
	padding: 0.5em;
	border: 0;
	color: #231f20;
	background-color: rgba(255,255,255,0.4);
	margin-bottom: 1em;
}

#ntc #colorrgb {

}

#ntc #colorlegend {
  text-align: center;
  font-size: 0.8em;
  margin: 8px;
}